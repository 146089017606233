import HttpUtil from '@/utils/HttpUtil';

// 获取流程图列表
export const getFlowList = (params?: any) => {
  let url = '/river-system/workflow/model/query/list';
  return HttpUtil.post(url, params);
};

// 获取流程历史版本
export const getHistoryFlows = lid => {
  let url = '/river-system/application/link/query/all/version';
  return HttpUtil.get(url, { id: lid });
};

// 启动
export const startup = (params?: any) => {
  let url = '/river-system/process/instance/startup';
  return HttpUtil.post(url, params);
};
// 部署
export const deploy = (id: string, params?: any) => {
  let url = `/river-system/workflow/model/deploy?id=${id}`;
  return HttpUtil.get(url, params);
};

// 获取流程列表
export const getProcessInstList = (params: any) => {
  let url = `/river-system/process/instance/query/list`;
  return HttpUtil.post(url, params);
};
// 获取任务列表
export const getTaskList = (params?: any) => {
  let url = '/river-system/task/runtime/query/list';
  return HttpUtil.post(url, params);
};
// 获取已完成的任务列表
export const getTaskHistoryList = (params?: any) => {
  let url = '/river-system/task/history/query/list';
  return HttpUtil.post(url, params);
};

// 提交任务
export const submitTask = (params?: any) => {
  let url = '/river-system/task/runtime/complete';
  return HttpUtil.post(url, params);
};
// 获取执行人列表
export const executorList: any = (params?: any) => {
  let url = '/river-system/task/runtime/executorList';
  return HttpUtil.post(url, params);
};

// 退回任务
export const backTask = (params?: any) => {
  let url = '/river-system/task/runtime/to/node';
  return HttpUtil.post(url, params);
};

// 获取流程自动操作
export const getFlowAutoOperations = (params?: any) => {
  let url = '/river-system/auto/operation/query/process/list';
  return HttpUtil.post(url, params);
};

// 获取流程节点自动操作
export const getNodeAutoOperationTypes = (params?: any) => {
  let url = '/river-system/auto/operation/query/node/list';
  return HttpUtil.get(url, params);
};

// 获取流程节点自动操作的参数
export const getNodeAutoOperationParams = (params?: any) => {
  let url = '/river-system/auto/operation/query/param/list';
  return HttpUtil.get(url, params);
};

// 保存流程图
export const saveFlow = (params?: any) => {
  let url = '/river-system/workflow/model/save';
  return HttpUtil.post(url, params);
};

// 保存流程图
export const workflowModelUpdateEditable = (params?: any) => {
  let url = '/river-system/workflow/model/update/editable';
  return HttpUtil.post(url, params);
};

// 保存流程图
export const updatgeFlow = (params?: any) => {
  let url = '/river-system/workflow/model/update';
  return HttpUtil.post(url, params);
};
// 获取流程图
export const getFlow = (id: string, params: any) => {
  let url = `/river-system/workflow/model/query/single?id=${id}`;
  return HttpUtil.get(url, params);
};

// 获取进行中流程图
export const getTaskDetail = (params: any) => {
  let url = `/river-system/task/history/query/detail`;
  return HttpUtil.get(url, params);
};

// 常用应用 - 获取流程列表
export const topicHistory = (params: any) => {
  let url = `/river-system/topic/biz/task/query/history`;
  return HttpUtil.post(url, params);
};

// 保存进行的流程图
export const saveGoingFlow = (params?: any) => {
  let url = '/river-system/process/runtime/bpm/reset';
  return HttpUtil.post(url, params);
};
// 获取流程运行明细
export const getRuntimeFlow = (runtimeId: string, params: any) => {
  let url = `/river-system/process/runtime/query/detail?runtimeId=${runtimeId}`;
  return HttpUtil.get(url, params);
};
// 抄送任务
export const ccTask = (params?: any) => {
  let url = '/river-system/task/history/cc';
  return HttpUtil.post(url, params);
};

// 加签任务
export const assignTask = (params?: any) => {
  let url = '/river-system/task/runtime/add/assignee';
  return HttpUtil.post(url, params);
};

// 转交任务
export const delegateTask = (params: any) => {
  let url = '/river-system/task/runtime/delegate';
  return HttpUtil.post(url, params);
};

// 跳转任务
export const jumpTask = (params: any) => {
  let url = '/river-system/process/instance/to/node';
  return HttpUtil.post(url, params);
};

// 跳转任务2
export const jumpTask2 = (params: any) => {
  let url = '/river-system/process/instance/to/nodeV2';
  return HttpUtil.post(url, params);
};

// 更改状态为已读
export const readRuntimeTask = (id: string) => {
  let url = '/river-system/task/runtime/read';
  return HttpUtil.get(url, { id: id });
};

/**
 * description  删除应用流程
 * @param id 流程id
 * @returns {}
 */
export const deleteAppFlow = (id: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'workflow/model/delete';
  return HttpUtil.get(url, { id });
};

/**
 * description  获取流程条件配置时的可选项
 * @param processId 流程id
 * @param paramOptionName 流程id
 * @param text 流程id
 * @returns {}
 */
export const getConditionValues = (params: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'parameter/query/condition/values';
  return HttpUtil.post(url, params);
};

/**
 * description  获取流程参数配置时的可选项
 * @param formId 流程选中的表单
 * @param processId 流程id
 * @param paramsOption 流程参数对象
 * @param text 流程id
 * @returns {}
 */
export const getFlowParamDataSource = (params: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'parameter/query/item/values';
  return HttpUtil.post(url, params);
};

/**
 * description
 */
export const getRealities = (params: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'parameter/query/realities';
  return HttpUtil.post(url, params);
};

/**
 * 获取常用
 */
export const getUsualFlows = (params: any) => {
  let url = '/river-system/process/instance/query/most/used';
  return HttpUtil.post(url, params);
};
