





import { Component, Vue, Watch } from 'vue-property-decorator';
import FlowGraph from '@/components/flow-design/flow-graph.vue';

@Component({
  name: 'flowReader',
  components: {
    FlowGraph
  }
})
export default class FlowReader extends Vue {
  taskDataId: string = '';
  flowId: string = '';

  created() {
    document.getElementById('app')!.style.minWidth = '100%';
    this.taskDataId = <string>this.$route.query.taskDataId;
    this.flowId = <string>this.$route.query.flowId;
  }
}
