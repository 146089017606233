var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reader-container" },
    [
      _c("flow-graph", {
        ref: "flowGraphRef",
        attrs: {
          readonly: true,
          "flow-id": _vm.flowId,
          "task-data-id": _vm.taskDataId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }